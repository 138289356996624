module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/@knowit-jamstack/gatsby-theme-knowit/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-borenius-headless","short_name":"Borenius","start_url":"/","background_color":"#f7f7f7","theme_color":"#000000","display":"minimal-ui","icon":"src/images/borenius-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9cc1ae413a48e569000f3af9fd24c8f8"},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cms.borenius.com/wp/graphql/"},
    },{
      plugin: require('../../../../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"searchAndReplace":[{"search":"https://borenius-production.netlify.app","replace":"https://www.borenius.com"},{"search":"https://borenius-production.netlify.app","replace":"https://www.borenius.com"}],"url":"https://cms.borenius.com/wp/graphql/","verbose":true,"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"schema":{"timeout":960000,"perPage":20,"requestConcurrency":2,"previewRequestConcurrency":2,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"html":{"useGatsbyImage":true,"placeholderType":"dominantColor","imageQuality":70,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false},"type":{"Page":{"where":"language: ALL","beforeChangeNode":"./gatsby/beforeChangeNode.js"},"Post":{"where":"language: ALL","beforeChangeNode":"./gatsby/beforeChangeNode.js","limit":10000},"Reference":{"limit":10000},"Person":{"limit":10000},"Tag":{"where":"language: ALL","beforeChangeNode":"./gatsby/beforeChangeNode.js"},"Taxonomy":{},"Redirect":{"limit":10000},"MenuItem":{"where":"language: ALL","beforeChangeNode":"./gatsby/beforeChangeNode.js"},"Menu":{},"Comment":{},"PostFormat":{},"UserRole":{},"User":{},"MediaItem":{"createFileNodes":false,"placeholderSizeName":"gatsby-image-placeholder","lazyNodes":false},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"catchLinks":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{},"allPageHeaders":[],"mergeSecurityHeaders":true,"mergeLinkHeaders":true,"mergeCachingHeaders":true,"generateMatchPathRewrites":true},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W44LKQ8","includeInDevelopment":false,"routeChangeEventName":"virtualpageview","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
